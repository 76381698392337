import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

const ProductListItem = ({ handleSelect, file, name, isSelected, slug }) => {
  return (
    <Row
      className="gx-card mx-0 align-items-center border-bottom border-200"
      key={slug}
    >
      <Col xs={12} className="py-3">
        <div className="d-flex align-items-center gap-3">
          <Form.Check
            type="checkbox"
            label=""
            className="d-flex"
            id={`prd-${slug}`}
          >
            <Form.Check.Input
              name="product-select"
              checked={isSelected}
              onChange={handleSelect}
              type="checkbox"
              className="me-2 align-self-center"
              style={{ minWidth: 16 }}
            />
          </Form.Check>
          <Form.Check.Label className="d-flex align-items-center cursor-pointer">
            <img
              src={file}
              width="60"
              alt={'product-image'}
              className="img-fluid rounded-1 me-3 d-none d-md-block"
            />
            <div className="flex-1">
              <h5 className="fs-0">{name}</h5>
            </div>
          </Form.Check.Label>
        </div>
      </Col>
    </Row>
  );
};

ProductListItem.propTypes = {
  handleSelect: PropTypes.func,
  file: PropTypes.string,
  name: PropTypes.string,
  stock_quantity: PropTypes.number,
  price: PropTypes.object,
  isSelected: PropTypes.bool,
  slug: PropTypes.string
};

export default ProductListItem;
