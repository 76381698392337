import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QuantityController from 'components/app/e-commerce/QuantityController';
import { currencyFormat } from 'helpers/utils';

const SelectedProductList = props => {
  const { products, setProducts } = props;
  const handleRemove = slug => {
    const new_products = products.filter(dt => dt.slug !== slug);
    setProducts(new_products);
  };
  const handleIncrease = dt => {
    const new_products = [...products];
    const my_product = products.filter(p => p.slug === dt.slug)[0];
    my_product.quantity += 1;

    const foundIndex = products.findIndex(x => x.slug == dt.slug);
    new_products[foundIndex] = my_product;
    setProducts(new_products);
  };

  const handleDecrease = dt => {
    const new_products = [...products];
    const my_product = products.filter(p => p.slug === dt.slug)[0];
    if (my_product.quantity > 1) {
      my_product.quantity -= 1;

      const foundIndex = products.findIndex(x => x.slug == dt.slug);
      new_products[foundIndex] = my_product;
      setProducts(new_products);
    }
  };

  const handleChange = (e, dt) => {
    const new_products = [...products];
    const my_product = products.filter(p => p.slug === dt.slug)[0];
    my_product.quantity = parseInt(e.target.value < 1 ? 1 : e.target.value);

    const foundIndex = products.findIndex(x => x.slug == dt.slug);
    new_products[foundIndex] = my_product;
    setProducts(new_products);
  };
  return (
    <div className="mt-3">
      {products.length > 0 ? (
        <>
          <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
            <Col xs={9} md={8} className="py-2">
              Name
            </Col>
            <Col xs={3} md={4}>
              <Row>
                <Col md={8} className="py-2 d-none d-md-block text-center">
                  Quantity
                </Col>
                <Col xs={12} md={4} className="text-end py-2">
                  Price
                </Col>
              </Row>
            </Col>
          </Row>
          {products.map((product, i) => {
            return (
              <Row
                key={i}
                className="gx-card mx-0 align-items-center border-bottom border-200"
              >
                <Col xs={8} className="py-3">
                  <div className="d-flex align-items-center">
                    <Link to="!#">
                      <img
                        src={product?.file}
                        width="60"
                        alt={product.name}
                        className="img-fluid rounded-1 me-3 d-none d-md-block"
                      />
                    </Link>
                    <div className="flex-1">
                      <h5 className="fs-0">
                        <Link to="!#" className="text-900">
                          {product.name}
                        </Link>
                      </h5>
                      <div className="fs--2 fs-md--1">
                        <Button
                          variant="link"
                          size="sm"
                          className="text-danger fs--2 fs-md--1 fw-normal p-0"
                          onClick={() => handleRemove(product.slug)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={4} className="py-3">
                  <Row className="align-items-center">
                    <Col
                      md={{ span: 8, order: 0 }}
                      xs={{ order: 1 }}
                      className="d-flex justify-content-end justify-content-md-center"
                    >
                      <div>
                        <QuantityController
                          quantity={product.quantity}
                          handleChange={e => handleChange(e, product)}
                          handleIncrease={() => handleIncrease(product)}
                          handleDecrease={() => handleDecrease(product)}
                          btnClassName="px-2"
                        />
                      </div>
                    </Col>
                    <Col
                      md={{ span: 4, order: 1 }}
                      xs={{ order: 0 }}
                      className="text-end ps-0 mb-2 mb-md-0 text-600"
                    >
                      {currencyFormat(
                        product.price.total_price * product.quantity
                      )}{' '}
                      ₺
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Row className="fw-bold gx-card">
            <Col xs={9} md={8} className="py-2 text-end text-900">
              Total
            </Col>
            <Col className="px-0">
              <Row className="gx-card mx-0">
                <Col md={7} className="py-2 d-none d-md-block text-center">
                  {products?.reduce((sum, dt) => sum + dt.quantity, 0)} (items)
                </Col>
                <Col
                  xs={12}
                  md={5}
                  className="text-end py-2 text-nowrap px-card"
                >
                  {currencyFormat(
                    products.reduce(
                      (sum, dt) => sum + dt.quantity * dt.price.total_price,
                      0
                    )
                  )}
                  ₺
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <p className="p-card mb-0 bg-light">You have no items</p>
      )}
    </div>
  );
};

SelectedProductList.propTypes = {
  products: PropTypes.array,
  setProducts: PropTypes.func
};

export default SelectedProductList;
