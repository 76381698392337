import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { saveAs } from 'file-saver';
import { currencyFormat } from 'helpers/utils';
import useUpdate from 'hooks/useUpdate';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import serviceUrl from 'services/serviceUrlList';
import { getNewsletters } from 'services/user';
import * as XLSX from 'xlsx';
import NewsletterTableFilter from './module/NewsletterTableFilter';

const NewsletterList = () => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0
  });
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const [isActive, setIsActive] = useState();

  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const { data, isLoading, refetch } = useQuery(
    ['getNewsletters', queryObj, sort],
    () =>
      getNewsletters({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const [updateResponse, updateRequest] = useUpdate({
    url: serviceUrl.user.newsletter.detail + selectedRowId,
    payload: { isActive: isActive }
  });

  useEffect(() => {
    // Update sonrası çalışması beklenen useEffect
    if (!updateResponse.data && !updateResponse.error) return;
    if (updateResponse.error === null) {
      toast.success(`Newsletter updated`, {
        theme: 'colored'
      });
      refetch();
    } else {
      if (updateResponse.error.data.name) {
        toast.error(`Name field must be unique!`, {
          theme: 'colored'
        });
      }
    }
  }, [updateResponse]);

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: Number(pageSize),
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.count
    });
  }, [data?.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'email',
      Header: 'E-Mail',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'is_member',
      Header: 'Is Member',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-start'
      },
      Cell: rowData => {
        const { is_member } = rowData.row.original;
        if (is_member) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Member
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Non-Member
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'createdAt',
      Header: 'Created Date',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: dt => {
        const { createdAt } = dt.row.original;
        return <>{moment(createdAt).format('DD/MM/YYYY')}</>;
      }
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated Date',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: {
        className: 'py-2 text-center'
      },
      Cell: dt => {
        const { updatedAt } = dt.row.original;
        return <>{moment(updatedAt).format('DD/MM/YYYY')}</>;
      }
    },
    {
      accessor: 'isActive',
      Header: 'Status',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { isActive } = rowData.row.original;
        return isActive ? (
          <SoftBadge pill bg="success">
            Active
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="danger">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      headerProps: { className: 'pe-1 text-end' },
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handleUpdate(id, true)}>
                Active
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleUpdate(id, false)}>
                Passive
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleUpdate = (id, bool) => {
    setSelectedRowId(id);
    if (bool) {
      setIsActive(true);
      setConfirmText({
        title: 'Newsletter Update',
        description:
          'Are you sure? Selected newsletter subscriber will be active!'
      });
    } else {
      setIsActive(false);
      setConfirmText({
        title: 'Newsletter Update',
        description:
          'Are you sure? Selected newsletter subscriber will be passive!'
      });
    }
    setShowConfirm(true);
  };

  const handleExportExcel = async () => {
    if (isLoadingExport) return;
    setIsLoadingExport(true);
    try {
      const response = await getNewsletters({ limit: 100000 });
      const dt = response.results ?? [];

      const body = [];
      console.log(dt);
      dt?.forEach(row => {
        const tempRow = [];
        columns.forEach(column => {
          let value = null;

          if (column.accessor == 'total_order_amount') {
            value = `${currencyFormat(row[column.accessor])} ₺`;
          } else if (column.accessor == 'is_member') {
            value = row[column.accessor] ? 'Yes' : 'No';
          } else if (
            column.accessor == 'createdAt' ||
            column.accessor == 'updatedAt'
          ) {
            value = moment(row[column.accessor]).format('DD/MM/YYYY HH:mm');
          } else if (column.accessor == 'isActive') {
            value = row[column.accessor] ? 'Active' : 'Passive';
          }
          tempRow.push(value ?? row[column.accessor]);
        });

        body.push(tempRow);
      });

      const worksheet = XLSX.utils.aoa_to_sheet([
        columns.map(column => column.Header),
        ...body
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        `Newsletter List ${moment().format('DD.MM.YYYY HH.mm')}.xlsx`
      );

      // eslint-disable-next-line no-empty
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                isBulkAction={false}
                title="Newsletter Subscriber List"
                handleRefetchAll={refetch}
                table
              />
              <NewsletterTableFilter
                queryObj={queryObj}
                setQueryObj={setQueryObj}
                isLoadingExport={isLoadingExport}
                handleExport={handleExportExcel}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                sort={sort}
                setSort={setSort}
              />
            </Card.Body>
            <Card.Footer>
              {data?.count > 0 && <AdvanceTablePagination table />}
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={updateRequest}
        type="update"
      />
    </div>
  );
};

export default NewsletterList;
