import MultiSelect from 'components/common/MultiSelect';
import AutocompleteTag from 'components/input/AutocompleteTag';
import useFetch from 'hooks/useFetch';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import serviceUrl from 'services/serviceUrlList';

const ProductOrganization = props => {
  const { formValues, setFormValues } = props;
  const [searchParams] = useSearchParams();
  const [selectedTags, setSelectedTags] = useState([]);
  const { response: categoriesResponse } = useFetch({
    url: serviceUrl.product.productCategory.mini + '?is_active=true'
  });
  const { response: brandResponse } = useFetch({
    url: serviceUrl.product.brand.mini + '?is_active=true'
  });
  const { response: collectionResponse } = useFetch({
    url: `${serviceUrl.product.collection.mini}?limit=1000&is_active=true`
  });

  const categoryOptions = categoriesResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const brandOptions = brandResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });
  const collectionOptions = collectionResponse?.data.results.map(dt => {
    return {
      value: dt.id,
      label: dt.name
    };
  });

  const handleStateBool = (value, bool) => {
    setFormValues({ ...formValues, [value]: bool });
  };

  const handleState = (value, info) => {
    if (info.name === 'collections') {
      if (
        value.filter(val => val.value === formValues.main_collection?.value)
          .length > 0
      ) {
        setFormValues({ ...formValues, [info.name]: value });
      } else {
        setFormValues({
          ...formValues,
          main_collection: null,
          [info.name]: value
        });
      }
      return;
    }
    setFormValues({ ...formValues, [info.name]: value });
  };

  useEffect(() => {
    setFormValues({ ...formValues, tag_names: selectedTags });
  }, [selectedTags]);

  useEffect(() => {
    if (!formValues.tag_names) return;
    setSelectedTags(formValues.tag_names);
  }, [formValues.tag_names]);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Organization
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label>Category</Form.Label>
              <Select
                options={categoryOptions}
                placeholder="Select Category"
                classNamePrefix="react-select"
                name="category"
                value={formValues.category}
                onChange={handleState}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="brand">
              <Form.Label>Brand</Form.Label>
              <Select
                options={brandOptions}
                placeholder="Select Brand"
                classNamePrefix="react-select"
                name="brand"
                value={formValues.brand}
                onChange={handleState}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="collection">
              <Form.Label>Collections</Form.Label>
              <MultiSelect
                closeMenuOnSelect={false}
                isMulti
                options={collectionOptions || []}
                name="collections"
                value={formValues.collections}
                onChange={handleState}
                placeholder="Select Collections"
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="main_collection">
              <Form.Label>Main Collection</Form.Label>
              <Select
                options={formValues.collections}
                name="main_collection"
                classNamePrefix="react-select"
                value={formValues.main_collection}
                onChange={handleState}
                placeholder="Main Collection"
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="tag">
              <Form.Label>Tags</Form.Label>
              <AutocompleteTag
                placeholder="Select your tags"
                selected={selectedTags}
                onChange={setSelectedTags}
                service="product"
              />
            </Form.Group>
          </Col>
          {(searchParams.get('type') === 'single' || formValues.type === 1) && (
            <Col xs={12} lg={6}>
              <Form.Group className="mb-3" controlId="tag">
                <Form.Label>Origin</Form.Label>
                <Form.Control
                  type="text"
                  name="origin"
                  value={formValues.origin}
                  onChange={event => {
                    setFormValues({
                      ...formValues,
                      origin: event.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
          )}
          <Col xs={12} lg={6}>
            <Form.Group className="mb-3" controlId="isFeatured">
              <Form.Label>Is Featured</Form.Label>
              <Form.Check
                name="isFeatured"
                checked={formValues.isFeatured}
                type="switch"
                className="switch-lg"
                label=""
                onChange={e => handleStateBool('isFeatured', e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductOrganization.propTypes = {
  register: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductOrganization;
