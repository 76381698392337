import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useDelete from 'hooks/useDelete';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { getDiscounts } from 'services/order';
import serviceUrl from '../../../services/serviceUrlList';
import DiscountsTableFilter from './module/DiscountsTableFilter';
import ModalSelectDiscountType from './module/ModalSelectDiscountType';
import discountTypes from './module/discountTypes.json';

const DiscountList = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    filter: 'active'
  });
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: '',
    request: () => {}
  });
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.order.discount.detail + selectedRowId?.id
  });
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery(
    ['get-discounts', queryObj],
    () => getDiscounts(queryObj),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const handleDiscountSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const handleDeleteItem = id => {
    setSelectedRowId({ id: id, type: 'delete' });
    setShowConfirm(true);
  };
  useEffect(() => {
    if (!selectedRowId) return;
    if (selectedRowId.type === 'delete') {
      setConfirmText({
        title: 'Delete Campaign',
        description: 'Are you sure? Selected campaign will be deleted!',
        type: 'delete',
        request: deleteRequest
      });
    }
  }, [selectedRowId]);
  useEffect(() => {
    if (deleteResponse.data === null && deleteResponse.error === null) return;
    if (deleteResponse.error) {
      if (deleteResponse.error.status === 400) {
        toast.error(deleteResponse.error.data.message, { theme: 'colored' });
      } else {
        toast.error('Something went wrong', { theme: 'colored' });
      }
      return;
    }
    console.log(deleteResponse);
    refetch();
    setShowConfirm(false);
    toast.success(`Campaign deleted`, {
      theme: 'colored'
    });
    setSelectedRowId(null);
  }, [deleteResponse]);

  const tabFilter = filterValue => {
    switch (filterValue) {
      case 'all':
        setQueryObj({ ...queryObj, filter: 'all' });

        break;
      case 'active':
        setQueryObj({ ...queryObj, filter: 'active' });
        break;
      case 'scheduled':
        setQueryObj({ ...queryObj, filter: 'scheduled' });
        break;
      case 'expired':
        setQueryObj({ ...queryObj, filter: 'expired' });
        break;
    }
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const columns = [
    {
      accessor: 'name',
      Header: 'Title',
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { title, id } = rowData.row.original;
        return (
          <Link to={`/order/campaign-update/${id}`}>
            <span>{title}</span>
          </Link>
        );
      }
    },
    {
      accessor: 'is_active',
      Header: 'Status',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { is_active } = rowData.row.original;
        if (is_active) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Active
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Passive
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'method',
      Header: 'Method',
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { method } = rowData.row.original;
        return <>{method === 1 ? 'Code' : 'Auto'}</>;
      }
    },
    {
      accessor: 'discount_code',
      Header: 'Code',
      cellProps: {
        className: 'text-center'
      }
    },
    {
      accessor: 'discount_type',
      Header: 'Type',
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { discount_type } = rowData.row.original;
        return (
          <>{discountTypes.filter(e => e.id === discount_type)[0]?.subtitle}</>
        );
      }
    },
    {
      accessor: 'is_combined',
      Header: 'Is Combined',
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { is_combined } = rowData.row.original;
        return <>{is_combined ? 'Yes' : 'No'}</>;
      }
    },
    {
      accessor: 'usage_count',
      Header: 'Used',
      cellProps: {
        className: 'text-center'
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      disableSortBy: true,
      headerProps: { className: 'text-end' },
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`/order/campaign-update/${id}`)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteItem(id)}>
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleNew = type => {
    navigate(`/order/campaign-add?type=${type}`);
  };

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Campaigns"
                handleRefetch={refetch}
                table
                handleNew={openModal}
                bulkTable={'Campaign'}
              />
              <DiscountsTableFilter
                eventKey={tabFilter}
                handleDiscountSearch={handleDiscountSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                rowClassName="align-middle"
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        request={confirmText.request}
        type={confirmText.type}
      />
      <ModalSelectDiscountType
        showModal={showModal}
        handleCloseModal={closeModal}
        onClick={handleNew}
      />
    </div>
  );
};

export default DiscountList;
