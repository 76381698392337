import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';

const CardMaxUse = props => {
  const { register, control, clearField, setValue } = props;
  const [toggleLimit, setToggleLimit] = useState(false);

  const usageLimitWatch = useWatch({ control, name: 'usage_limit' });
  const isLimitlessWatch = useWatch({ control, name: 'isLimitless' });

  useEffect(() => {
    if (isLimitlessWatch) {
      setToggleLimit(false);
      return;
    }
    if (usageLimitWatch) setToggleLimit(true);
  }, [usageLimitWatch, isLimitlessWatch]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Maximum campaign uses</Card.Title>
        <Flex>
          <Form.Group className="mb-1 me-2" controlId="isLimitless">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={toggleLimit}
                onChange={event => {
                  setToggleLimit(event.target.checked);
                  if (event.target.checked) {
                    setValue('usage_limit', '1');
                    setValue('isLimitless', false);
                  } else {
                    setValue('isLimitless', true);
                  }
                }}
              />
              <Form.Check.Label className="m-0">
                Limit number of times this campaign can be used in total
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          {toggleLimit && (
            <Form.Group className="mb-1 flex-fill" controlId="usage_limit">
              <Form.Control
                {...register('usage_limit')}
                type="number"
                className="input-spin-none"
                onFocus={clearField}
                onWheel={e => e.target.blur()}
              />
            </Form.Group>
          )}
        </Flex>
        <Form.Group className="mb-1" controlId="is_one_usage">
          <Form.Check
            {...register('is_one_usage')}
            type="checkbox"
            className="me-2"
            label="Limit to one use per customer"
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};
CardMaxUse.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  clearField: PropTypes.func,
  control: PropTypes.object,
  handleChange: PropTypes.func
};
export default CardMaxUse;
