import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SelectedProductList = props => {
  const { products, setProducts } = props;
  const handleRemove = slug => {
    const new_products = products.filter(dt => dt.slug !== slug);
    setProducts(new_products);
  };
  return (
    <div className="mt-3">
      {products.length > 0 ? (
        <>
          <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
            <Col xs={9} md={8} className="py-2">
              Name
            </Col>
          </Row>
          {products.map((product, i) => {
            return (
              <Row
                key={i}
                className="gx-card mx-0 align-items-center border-bottom border-200"
              >
                <Col xs={8} className="py-3">
                  <div className="d-flex align-items-center">
                    <Link to="!#">
                      {product?.files && (
                        <img
                          src={product.files[0].file}
                          width="30"
                          alt={'variant'}
                          className="img-fluid rounded-1 me-3 d-none d-md-block"
                        />
                      )}
                    </Link>
                    <div className="flex-1">
                      <h6 className="fs-0">
                        <a
                          href={`/product/product-update/${product.slug}`}
                          className="text-900"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {product.name}
                        </a>
                      </h6>
                      <div className="fs--2 fs-md--1">
                        <Button
                          variant="link"
                          size="sm"
                          className="text-danger fs--2 fs-md--1 fw-normal p-0"
                          onClick={() => handleRemove(product.slug)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      ) : (
        <p className="p-card mb-0 bg-light">You have no items</p>
      )}
    </div>
  );
};

SelectedProductList.propTypes = {
  products: PropTypes.array,
  setProducts: PropTypes.func
};

export default SelectedProductList;
