import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import ModalSelectItem from './ModalSelectItem';
import SelectedItems from './SelectedItems';
const CardXY = props => {
  const { register, setValue, control } = props;
  const [quantityType, setQuantityType] = useState(1);
  const [showModalX, setShowModalX] = useState(false);
  const [showModalY, setShowModalY] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItemsX, setSelectedItemsX] = useState([]);
  const [selectedItemsY, setSelectedItemsY] = useState([]);
  const [itemTypeX, setItemTypeX] = useState('product');
  const [itemTypeY, setItemTypeY] = useState('product');
  const [isPercentage, setIsPercentage] = useState(true);

  const openModalX = () => setShowModalX(true);
  const closeModalX = () => setShowModalX(false);
  const openModalY = () => setShowModalY(true);
  const closeModalY = () => setShowModalY(false);

  const handleChangeQType = ({ target }) => {
    setQuantityType(target.value);
  };

  const appliesTypeX = useWatch({ control, name: 'applies_type_x' });
  const appliesToX = useWatch({ control, name: 'applies_to_x' });
  const appliesTypeY = useWatch({ control, name: 'applies_type_y' });
  const appliesToY = useWatch({ control, name: 'applies_to_y' });

  const valueType = useWatch({ control, name: 'value_type' });

  useEffect(() => {
    setItemTypeX(
      parseInt(appliesTypeX) === 2
        ? 'collection'
        : parseInt(appliesTypeX) === 3
        ? 'product'
        : parseInt(appliesTypeX) === 4
        ? 'brand'
        : 'product'
    );
    setSelectedItemsX(Array.isArray(appliesToX) ? appliesToX : []);
  }, [appliesTypeX]);
  useEffect(() => {
    setItemTypeY(
      parseInt(appliesTypeY) === 2
        ? 'collection'
        : parseInt(appliesTypeY) === 3
        ? 'product'
        : parseInt(appliesTypeY) === 4
        ? 'brand'
        : 'product'
    );
    setSelectedItemsY(Array.isArray(appliesToY) ? appliesToY : []);
  }, [appliesTypeY]);

  const handleChange = ({ target }, name) => {
    const value = parseInt(target.value);
    if (name === 'x') {
      setSelectedItemsX([]);
      if (value === 2) {
        setItemTypeX('collection');
      } else if (value === 3) {
        setItemTypeX('product');
      } else if (value === 4) {
        setItemTypeX('brand');
      } else if (value === 5) {
        setItemTypeX('');
      }
    } else {
      setSelectedItemsY([]);
      if (value === 2) {
        setItemTypeY('collection');
      } else if (value === 3) {
        setItemTypeY('product');
      } else if (value === 4) {
        setItemTypeY('brand');
      } else if (value === 5) {
        setItemTypeY('');
      }
    }
  };
  const handelInputChange = (event, name) => {
    setSearchValue(event.target.value);
    if (name === 'x') {
      openModalX();
    } else {
      openModalY();
    }
  };

  useEffect(() => {
    const temp_arr = selectedItemsX?.map(e => e.id);
    setValue('applies_to_x', temp_arr.toString());
  }, [selectedItemsX]);
  useEffect(() => {
    const temp_arr = selectedItemsY.map(e => e.id);
    setValue('applies_to_y', temp_arr.toString());
  }, [selectedItemsY]);

  const handleIsPercentage = bool => {
    if (bool) {
      setValue('value_type', 1);
    } else {
      setValue('value_type', 2);
    }
    setIsPercentage(bool);
  };

  const modalPropsX = {
    searchValue,
    setSearchValue,
    showModal: showModalX,
    closeModal: closeModalX,
    selectedItems: selectedItemsX,
    setSelectedItems: setSelectedItemsX,
    itemType: itemTypeX
  };
  const modalPropsY = {
    searchValue,
    setSearchValue,
    showModal: showModalY,
    closeModal: closeModalY,
    selectedItems: selectedItemsY,
    setSelectedItems: setSelectedItemsY,
    itemType: itemTypeY
  };
  return (
    <Card className="mb-3">
      <Card.Header className="border-bottom-2">
        <Card.Title className="mb-3">
          Customer {parseInt(quantityType) === 1 ? 'buys' : 'spends'}
        </Card.Title>
        <Form.Group className="mb-1" controlId="applies_x_quantity_type_amount">
          <Form.Check
            {...register('applies_x_quantity_type')}
            inline
            type="radio"
            value={1}
            className="me-2"
            defaultChecked
            onChange={handleChangeQType}
          />
          <Form.Label className="mb-0">Minimum quantity of items</Form.Label>
        </Form.Group>
        <Form.Group className="mb-1" controlId="applies_x_type_quantity">
          <Form.Check
            {...register('applies_x_quantity_type')}
            inline
            type="radio"
            value={2}
            className="me-2"
            onChange={handleChangeQType}
          />
          <Form.Label className="mb-0">Minimum purchase amount</Form.Label>
        </Form.Group>
        <Flex>
          <Form.Group className="mb-3 me-2" controlId="applies_x_quantity">
            <Form.Label>
              {parseInt(quantityType) === 1 ? 'Quantity' : 'Amount'}
            </Form.Label>
            <InputGroup>
              {parseInt(quantityType) !== 1 && (
                <InputGroup.Text>₺</InputGroup.Text>
              )}
              <Form.Control
                placeholder={parseInt(quantityType) === 1 ? '' : '0.00'}
                {...register('applies_x_quantity')}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 flex-fill" controlId="applies_x_quantity">
            <Form.Label>Any items from</Form.Label>
            <Form.Select
              {...register('applies_type_x')}
              onChange={e => handleChange(e, 'x')}
            >
              <option value={3}>Spesific products</option>
              <option value={2}>Spesific collections</option>
              <option value={4}>Spesific brand</option>
              <option value={5}>
                Basket {parseInt(quantityType) === 1 ? 'quantity' : 'amount'}
              </option>
            </Form.Select>
          </Form.Group>
        </Flex>
        {itemTypeX !== '' && (
          <Row>
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Text>
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="search-open-modal"
                  placeholder={
                    itemTypeX === 'collection'
                      ? 'Search Collections'
                      : itemTypeX === 'product'
                      ? 'Search Products'
                      : itemTypeX === 'brand'
                      ? 'Search Brands'
                      : ''
                  }
                  onChange={e => handelInputChange(e, 'x')}
                />
                <Button
                  variant="outline-primary"
                  onClick={e => handelInputChange(e, 'x')}
                >
                  Browse
                </Button>
              </InputGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <SelectedItems
              items={selectedItemsX}
              setItems={setSelectedItemsX}
              itemType={itemTypeX}
            />
          </Col>
        </Row>
        <ModalSelectItem {...modalPropsX} />
      </Card.Header>

      <Card.Body>
        <Card.Title className="mb-3">Customer gets</Card.Title>
        <Card.Subtitle className="mb-3 text-600">
          Customers must add the quantity of items specified below to their
          cart.
        </Card.Subtitle>
        <Flex>
          <Form.Group className="mb-3 me-2" controlId="applies_y_quantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control {...register('applies_y_quantity')} />
          </Form.Group>
          <Form.Group className="mb-3 flex-fill" controlId="applies_y_quantity">
            <Form.Label>Any items from</Form.Label>
            <Form.Select
              {...register('applies_type_y')}
              onChange={e => handleChange(e, 'y')}
            >
              <option value={3}>Spesific products</option>
              <option value={2}>Spesific collections</option>
              <option value={4}>Spesific brand</option>
              <option value={5}>Basket discount</option>
            </Form.Select>
          </Form.Group>
        </Flex>
        {itemTypeY !== '' && (
          <Row>
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Text>
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="search-open-modal"
                  placeholder={
                    itemTypeY === 'collection'
                      ? 'Search Collections'
                      : itemTypeY === 'product'
                      ? 'Search Products'
                      : itemTypeY === 'brand'
                      ? 'Search Brands'
                      : ''
                  }
                  onChange={e => handelInputChange(e, 'y')}
                />
                <Button
                  variant="outline-primary"
                  onClick={e => handelInputChange(e, 'y')}
                >
                  Browse
                </Button>
              </InputGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <SelectedItems
              items={selectedItemsY}
              setItems={setSelectedItemsY}
              itemType={itemTypeY}
            />
          </Col>
        </Row>
        <ModalSelectItem {...modalPropsY} />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col lg={6}>
            <Card.Subtitle className="my-3 text-1100">
              At a campaigned value
            </Card.Subtitle>
            <Flex direction="row">
              <Form.Group className="pe-2" controlId="discountCode">
                <InputGroup>
                  <Button
                    variant={valueType == 1 ? 'secondary' : 'outline-secondary'}
                    onClick={() => handleIsPercentage(true)}
                    id="btnPercentage"
                  >
                    Percentage
                  </Button>
                  <Button
                    variant={valueType == 2 ? 'secondary' : 'outline-secondary'}
                    onClick={() => handleIsPercentage(false)}
                    id="btnFixed"
                  >
                    Fixed Amount
                  </Button>
                  {itemTypeY === 'product' && selectedItemsY.length > 0 && (
                    <Button
                      variant={
                        valueType == 3 ? 'secondary' : 'outline-secondary'
                      }
                      onClick={() => {
                        setIsPercentage(false);
                        setValue('value_type', 3);
                      }}
                      id="btnFixed"
                    >
                      Promotion
                    </Button>
                  )}
                </InputGroup>
              </Form.Group>
              {valueType !== 3 && (
                <Form.Group className="flex-fill" controlId="percentage">
                  <InputGroup>
                    <Form.Control
                      {...register('value')}
                      placeholder={isPercentage ? '' : '0.00'}
                    />
                    {isPercentage ? (
                      <InputGroup.Text>%</InputGroup.Text>
                    ) : (
                      <InputGroup.Text>TL</InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              )}
            </Flex>
          </Col>
          <Col lg={6}>
            <Form.Group
              className="my-3 flex-fill"
              controlId="applies_x_quantity"
            >
              <Form.Label>Apply campaign to</Form.Label>
              <Form.Select {...register('is_apply_second')}>
                <option value={true}>Only customer gets</option>
                <option value={false}>All</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

CardXY.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleChange: PropTypes.func,
  control: PropTypes.object,
  getValue: PropTypes.object
};

export default CardXY;
