import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { BsSearch } from 'react-icons/bs';
import { useState } from 'react';
import ProductListItem from './ProductListItem';
import { AiOutlineLeft } from 'react-icons/ai';
import { getProductListMini } from 'services/product';
import { useQuery } from 'react-query';

const SelectProductsModal = props => {
  const {
    selectedProducts,
    setSelectedProducts,
    searchValue,
    setSearchValue,
    showModal,
    closeModal
  } = props;
  const [currentSelectedProducts, setCurrentSelectedProducts] = useState();
  const [apiSearch, setApiSearch] = useState('');
  const { data, isLoading } = useQuery(
    ['product-filter', apiSearch],
    () => getProductListMini({ search: apiSearch }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (showModal) {
      setCurrentSelectedProducts(selectedProducts);
    }
  }, [showModal]);

  const handleCloseModal = () => {
    closeModal();
    setSelectedProducts(currentSelectedProducts);
  };
  const handleAdd = () => {
    closeModal();
  };

  const searchTimeoutRef = useRef(null);
  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setApiSearch(searchValue);
    }, 700);
  }, [searchValue]);
  const handleSearchProducts = event => {
    setSearchValue(event.target.value);
  };

  const handleSelectProduct = (event, dt) => {
    const new_arr = [...selectedProducts];
    if (event.target.checked) {
      new_arr.push(dt);
      setSelectedProducts(new_arr);
    } else {
      const filtered_arr = new_arr.filter(product => dt.slug !== product.slug);
      setSelectedProducts(filtered_arr);
    }
  };
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      keyboard={false}
    >
      <Modal.Header>
        <AiOutlineLeft
          size={24}
          className="cursor-pointer"
          onClick={() => {}}
        />
        &nbsp;&nbsp;&nbsp;
        <Modal.Title>Select Products</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <InputGroup>
              <InputGroup.Text>
                <BsSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                autoFocus
                name="search-products"
                placeholder="Search Products"
                value={searchValue}
                onChange={handleSearchProducts}
              />
            </InputGroup>
          </Col>
        </Row>
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div
            className="mt-3"
            style={{
              height: 300,
              overflowY: 'auto'
            }}
          >
            {data?.data.results?.map(dt => {
              return (
                <ProductListItem
                  handleSelect={e => handleSelectProduct(e, dt)}
                  key={dt.id}
                  isSelected={selectedProducts.some(d => d.slug === dt.slug)}
                  file={dt.files ? dt.files[0].file : ''}
                  name={dt.name}
                />
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          disabled
          style={{ position: 'absolute', left: 12 }}
        >
          {selectedProducts.length} Selected
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="success" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelectProductsModal.propTypes = {
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func
};

export default SelectProductsModal;
