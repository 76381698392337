import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { generateCode } from 'services/order';
import discountTypes from './discountTypes.json';
import TextEditor from 'components/common/TextEditor';
import { useWatch } from 'react-hook-form';

const CardMethod = props => {
  const { register, setValue, errors, discountTypesWatch, control } = props;
  const [searchParams] = useSearchParams();
  const [toggleCode, setToggleCode] = useState(true);
  const handleGenerateCode = useMutation({
    mutationFn: generateCode,
    onSuccess: data => setValue('discount_code', data.data.discount_code)
  });
  const type = searchParams.get('type');
  const methodWatch = useWatch({ control, name: 'method' });

  useEffect(() => {
    if (methodWatch === 2 || methodWatch === '2') {
      setToggleCode(false);
    }
  }, [methodWatch]);

  const handleChange = ({ target }) => {
    if (target.value === '2') {
      setToggleCode(false);
      setValue('discount_code', '');
    } else if (target.value === '1') {
      setToggleCode(true);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header className="border-bottom-2">
        <Flex direction={'row'} justifyContent={'between'}>
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {discountTypes.filter(e => e.value === type)[0]?.title}
          </h5>
          <span className="text-500 mb-0 text-nowrap py-2 py-xl-0">
            {discountTypes.filter(e => e.value === type)[0]?.subtitle}
          </span>
        </Flex>
      </Card.Header>
      <Card.Body>
        {discountTypesWatch !== 4 && (
          <>
            <Card.Title>Method</Card.Title>
            <Form.Group className="mb-1" controlId="code">
              <Form.Check
                {...register('method')}
                inline
                type="radio"
                name="method"
                value={1}
                className="me-2"
                defaultChecked
                onClick={handleChange}
              />
              <Form.Label className="mb-0">Code</Form.Label>
            </Form.Group>

            <Form.Group className="mb-3" controlId="auto">
              <Form.Check
                {...register('method')}
                inline
                type="radio"
                name="method"
                value={2}
                className="me-2"
                onClick={handleChange}
              />
              <Form.Label className="mb-0">Automatic</Form.Label>
            </Form.Group>
          </>
        )}
        {toggleCode && (
          <Form.Group className="mb-3" controlId="discountCode">
            <Form.Label>Campaign Code</Form.Label>
            <InputGroup>
              <Form.Control
                {...register('discount_code', { required: true })}
                placeholder="Campaign Code"
                aria-label="Campaign Code"
                aria-describedby="btnGenerate"
                isInvalid={errors?.discount_code}
              />
              <Button
                variant="outline-secondary"
                onClick={handleGenerateCode.mutate}
                id="btnGenerate"
              >
                Generate
              </Button>
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Text>Customers must enter this code at checkout.</Form.Text>
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="Title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Title"
            {...register('title', { required: true })}
            isInvalid={errors?.title}
          />
          <Form.Control.Feedback type="invalid">
            This field cannot be empty!
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="discount_text">
          <Form.Label>Campaign Text</Form.Label>
          <TextEditor
            name="discount_text"
            control={control}
            maxLength={5000}
            tabIndex={1} // tabIndex of textarea
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

CardMethod.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  discountTypesWatch: PropTypes.number,
  control: PropTypes.object
};

export default CardMethod;
