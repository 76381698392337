import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext, LoaderContext } from 'context/Context';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import React, { Fragment, useContext, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { protectedApi } from 'services/axios';
import { createTagFromProduct } from 'services/content';
import { deleteProduct } from 'services/product';
import serviceUrl from 'services/serviceUrlList';
import AddProductForm from './module/AddProductForm';

const AddProduct = () => {
  const { user } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    is_update: false,
    is_active: false,
    is_archived: false,
    isFeatured: false,
    set_quantity: 0,
    bundle_quantity: [],
    type: 1,
    name: '',
    display_name: '',
    descriptions: [],
    attributes: [],
    products: [],
    tag_names: [],
    collections: [],
    main_collection: {},
    category: {},
    brand: {},
    origin: '',
    serving_size: 0,
    serving_capacity: 0,
    serving_unit: undefined,
    highlight_title: '',
    highlight_detail: '',
    seo_setting: {},
    sku: '',
    barcode: '',
    is_promotion: false,
    files: [],
    files_mobile: [],
    follow: true,
    index: true,
    page_title: '',
    meta_desc: '',
    persona_and_goals: [],
    product_video: '',
    variants: []
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { setLoading } = useContext(LoaderContext);

  const navigate = useNavigate();

  const { response: product_detail } = slug
    ? useFetch({
        url: serviceUrl.product.product.detail + slug
      })
    : {};

  // eslint-disable-next-line no-unused-vars
  // const [deleteResponse, deleteRequest] = useDelete({
  //   url: serviceUrl.product.product.detail + slug
  // });
  const onSubmit = async () => {
    setLoading(true);
    const collections = formValues?.collections?.map(dt => ({
      id: dt.value
    }));
    const main_collection = formValues?.main_collection?.value;
    if (
      searchParams.get('type') === 'set' &&
      (formValues.products.length === 0 || formValues.set_quantity <= 1)
    ) {
      toast.error(
        'Select set product! Set product quantity can not be less than 2',
        {
          theme: 'colored'
        }
      );
      setLoading(false);
      return;
    }
    if (
      searchParams.get('type') === 'bundle' &&
      (formValues.products.length === 0 ||
        formValues.bundle_quantity.length === 0 ||
        formValues.bundle_quantity.includes(0))
    ) {
      toast.error(
        'Select bundle product! Bundle product quantity can not be less than 1',
        {
          theme: 'colored'
        }
      );
      setLoading(false);
      return;
    }
    if (collections.length === 0) {
      toast.error('Collection cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (!main_collection) {
      toast.error('Main Collection cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (!formValues.sku) {
      toast.error('SKU cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    // if (!formValues.skt) {
    //   toast.error('SKT cannot be empty', {
    //     theme: 'colored'
    //   });
    //   setLoading(false);
    //   return;
    // }
    if (!formValues.barcode) {
      toast.error('Barcode cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (!formValues.brand.value) {
      toast.error('Brand cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (!formValues.category.value) {
      toast.error('Category cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    // if (
    //   !formValues.prices ||
    //   formValues.prices.length === 0 ||
    //   formValues.prices.find(dt => dt.is_active)?.total_price === 0
    // ) {
    //   toast.error('Price cannot be empty', {
    //     theme: 'colored'
    //   });
    //   setLoading(false);
    //   return;
    // }
    const medias = [];
    const mediasMobile = [];
    const files_promise = formValues?.files?.map(async dt => {
      if (typeof dt.file === 'string') {
        medias.push(dt);
        return;
      }
      const fileData = new FormData();
      fileData.append('file', dt.file);
      fileData.append('alt_text', dt.alt_text);
      fileData.append('name', formValues.display_name);
      fileData.append('device_type', 'desktop');
      const response = await createFile(fileData);
      const file_obj = {
        id: response.data.id,
        alt_text: response.data.alt_text,
        file: response.data.file,
        size: dt.size
      };
      medias.push(file_obj);
    });
    if (formValues.files) {
      await Promise.all(files_promise);
    }
    const files_promise_mobile = formValues?.files_mobile?.map(async dt => {
      if (typeof dt.file === 'string') {
        mediasMobile.push(dt);
        return;
      }
      const fileData = new FormData();
      fileData.append('file', dt.file);
      fileData.append('alt_text', dt.alt_text);
      fileData.append('name', formValues.display_name);
      fileData.append('device_type', 'mobile');
      const response = await createFile(fileData);
      const file_obj = {
        id: response.data.id,
        alt_text: response.data.alt_text,
        file: response.data.file,
        size: dt.size
      };
      mediasMobile.push(file_obj);
    });
    if (formValues.files_mobile) {
      await Promise.all(files_promise_mobile);
    }
    let videoUrl = '';
    if (formValues.video) {
      const videoData = new FormData();
      videoData.append('file', formValues.video);
      const videoResponse = await createFile(videoData);
      if (videoResponse.status === 201) {
        videoUrl = videoResponse.data.file;
      }
    }

    const payload = { ...formValues };
    payload.files = [...medias];
    payload.files_mobile = [...mediasMobile];

    payload.product_video = videoUrl;
    payload.brand = payload.brand?.value;
    payload.category = payload.category?.value;
    payload.serving_unit = payload.serving_unit?.value;
    payload.collections = payload?.collections?.map(dt => ({
      id: dt.value
    }));
    payload.main_collection = payload?.main_collection?.value;
    if (payload.attributes.filter(dt => !dt).length > 0) {
      delete payload.attributes;
    }
    if (!payload.persona_and_goals) {
      delete payload.persona_and_goals;
    }
    if (payload.prices && payload.prices.length > 0) {
      const activePrice = payload.prices.find(dt => dt.is_active);
      payload.total_post_price = activePrice?.total_post_price;
      payload.total_price = activePrice?.total_price;
    }
    if (payload.skt) {
      payload.skt = moment(payload.skt).format('YYYY-MM-DD');
    }
    if (!slug) {
      payload.type =
        searchParams.get('type') === 'single'
          ? 1
          : searchParams.get('type') === 'set'
          ? 2
          : searchParams.get('type') === 'bundle'
          ? 3
          : undefined;
    }
    payload.products = payload.products?.map(dt => ({ id: dt.id }));
    if (payload.products.length === 0) {
      delete payload.products;
    }
    // validation start
    if (payload.collections.length === 0) {
      toast.error('Collection cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }
    if (!payload.main_collection) {
      toast.error('Main Collection cannot be empty', {
        theme: 'colored'
      });
      setLoading(false);
      return;
    }

    payload.variants = payload?.variants?.map(dt => dt.id);

    // validation end
    if (!slug) {
      payload.created_by = user.id;
      const res = await createProduct(payload);
      if (res.status === 201) {
        toast.success(`Product created`, {
          theme: 'colored'
        });
        // to sync tag names for content and product services
        await createTagFromProduct({
          tag_names: formValues.tag_names,
          created_by: user.id
        });
        handleRouteList();
      } else if (res.status === 400) {
        if (res.data.name) {
          toast.error(`Name field must be unique.`, {
            theme: 'colored'
          });
        }
      } else {
        toast.error('An error occurred', {
          theme: 'colored'
        });
      }
    } else {
      payload.updated_by = user.id;
      if (payload.prices.length === product_detail.data.prices.length) {
        delete payload.prices;
      } else {
        payload.prices = payload.prices.filter(
          price => price.is_active === true
        );
      }
      const res = await updateProduct(payload, product_detail.data.id);
      if (res.status === 200) {
        toast.success(`Product updated`, {
          theme: 'colored'
        });
        if (payload.removedFiles?.length > 0) {
          payload.removedFiles.map(async file => {
            await removeFile(file.id);
          });
        }
        // to sync tag names for content and product services
        await createTagFromProduct({
          tag_names: formValues.tag_names,
          updated_by: user.id
        });

        handleRouteList();
      } else if (res.status === 400) {
        if (res.data.name) {
          toast.error(`Name field must be unique.`, {
            theme: 'colored'
          });
        } else if (res.data.slug) {
          toast.error(res.data.slug, {
            theme: 'colored'
          });
        }
      } else {
        toast.error('An error occurred', {
          theme: 'colored'
        });
      }
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (deleteResponse.data === null && deleteResponse.error === null) return;
  //   if (deleteResponse.data === '') {
  //     toast.success(`Product deleted`, {
  //       theme: 'colored'
  //     });
  //     handleRouteList();
  //     return;
  //   }
  //   if (deleteResponse.error) {
  //     toast.error(deleteResponse.error.data.message, {
  //       theme: 'colored'
  //     });
  //   }
  // }, [deleteResponse]);

  const handleArciveProduct = async () => {
    const payload = {
      is_active: false,
      is_archived: true
    };
    const res = await updateProduct(payload, product_detail.data.id);
    if (res.status === 200) {
      toast.success(
        `Product ${
          formValues.is_archived ? 'unarchived' : 'archived'
        } successfully`,
        {
          theme: 'colored'
        }
      );
      handleRouteList();
    }
  };

  const handleDeleteProduct = async () => {
    setLoading(true);
    let deleteResponse;
    const whatIsType = () => {
      if (slug) {
        switch (formValues.type) {
          case 1:
            return 'single';
          case 2:
            return 'set';
          case 3:
            return 'bundle';
          default:
        }
      } else {
        return searchParams.get('type');
      }
    };
    const type_ = whatIsType();
    if (type_ !== 'single') {
      const updateProduct_ = await updateProduct(
        { products: [] },
        product_detail.data.id
      );
      if (updateProduct_.status === 200) {
        deleteResponse = await deleteProduct(user.id, slug);
      }
      // products alınını temizlemek için istek çık sonra sil
    } else {
      // direkt sil
      deleteResponse = await deleteProduct(user.id, slug);
    }

    if (deleteResponse.status === 204) {
      handleRouteList();
      toast.success(`Product deleted`, {
        theme: 'colored'
      });
    } else {
      toast.error(`Uppss! something went wrong!!`, {
        theme: 'colored'
      });
    }
    setLoading(false);
  };

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Product',
      description: 'Are you sure? Selected product will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };

  const handleRouteList = () => {
    navigate('/product/product-list');
  };

  const formProps = {
    slug,
    onSubmit,
    formValues,
    setFormValues,
    handleDeleteItem,
    handleArciveProduct,
    handleRouteList,
    product_detail,
    user
  };

  return (
    <Fragment>
      <AddProductForm {...formProps} />
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={handleDeleteProduct}
      />
    </Fragment>
  );
};

export default AddProduct;

export const createFile = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.file.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const removeFile = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.file.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createProduct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.product.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateProduct = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.product.detail_id + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
