import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import SelectProducts from './SelectProducts';

function ProductVariants(props) {
  const { formValues, setFormValues } = props;

  const setSelectedProducts = dt => {
    setFormValues(prev => ({
      ...prev,
      variants: dt
    }));
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Variants
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <SelectProducts
          selectedProducts={formValues.variants}
          setSelectedProducts={setSelectedProducts}
        />
      </Card.Body>
    </Card>
  );
}

ProductVariants.propTypes = {
  register: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductVariants;
