import ConfirmationModal from 'components/modal/ConfirmationModal';
import { AuthContext, LoaderContext } from 'context/Context';
import useDelete from 'hooks/useDelete';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDiscount, getDiscount, updateDiscount } from 'services/order';
import serviceUrl from '../../../services/serviceUrlList.json';
import CardActiveDates from './module/CardActiveDates';
import CardCombination from './module/CardCombination';
import CardCustomerEligibility from './module/CardCustomerEligibility';
import CardMaxUse from './module/CardMaxUse';
import CardMethod from './module/CardMethod';
import CardRequirements from './module/CardRequirements';
import CardValue from './module/CardValue';
import CardXY from './module/CardXY';
import discountTypes from './module/discountTypes.json';

const AddDiscount = () => {
  const initialValues = {
    is_active: true,
    value: 0,
    requirement_value: 0,
    usage_limit: 0,
    discount_type: 0,
    method: 1,
    start_date: moment(),
    end_date: moment(),
    isLimitless: true
  };
  const [searchParams] = useSearchParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '',
    description: '',
    type: ''
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    defaultValues: initialValues
  });

  const discountTypesWatch = useWatch({ control, name: 'discount_type' });

  const { data } = useQuery(['getdiscount'], () => id && getDiscount(id), {
    keepPreviousData: true,
    staleTime: Infinity
  });

  const [deleteResponse, deleteRequest] = useDelete({
    url: serviceUrl.order.discount.detail + id
  });

  const onSubmit = async data => {
    if (!data.value_type) {
      toast.error('You should select a campaigned value', { theme: 'colored' });
      return;
    }
    if (!data.value || data.value === '0') {
      toast.error('You should enter a value', { theme: 'colored' });
      return;
    }
    // if (!data.applies_to_x || data.applies_to_x === '') {
    //   toast.error('You should select items for Customer buys', {
    //     theme: 'colored'
    //   });
    //   return;
    // }
    setLoading(true);
    if (data.wcombined?.length > 0) {
      data.wcombined = data.wcombined.toString();
    }
    if (typeof data.applies_to_x === 'object' && data.applies_to_x) {
      data.applies_to_x = data.applies_to_x?.map(e => e.id).toString();
    }
    if (typeof data.applies_to_y === 'object' && data.applies_to_y) {
      data.applies_to_y = data.applies_to_y?.map(e => e.id).toString();
    }
    if (typeof data.combinedDiscounts === 'object' && data.combinedDiscounts) {
      data.combinedDiscounts = data.combinedDiscounts?.map(e => e.id);
    }
    if (!id) {
      // if start date is less then now, set it to now
      if (moment(data.start_date).isBefore(moment())) {
        data.start_date = moment().format('YYYY-MM-DDTHH:mm');
      }
      handleCreateDiscount(data);
    } else {
      handleUpdateDiscount(data);
    }
    setLoading(false);
  };

  const handleCreateDiscount = async data => {
    const res = await createDiscount(data);
    if (res.status === 201) {
      handleRouteList();
      toast.success('Campaign successfully created.', { theme: 'colored' });
    } else if (res.status === 400) {
      if (res.data) {
        toast.error(res.data[Object.keys(res.data)[0]][0], {
          theme: 'colored'
        });
      }
    } else {
      toast.error('Something went wrong', { theme: 'colored' });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleUpdateDiscount = async ({ customers, ...rest }) => {
    const res = await updateDiscount(id, { ...rest });
    if (res.status === 200) {
      handleRouteList();
      toast.success('Campaign successfully updated.', { theme: 'colored' });
    } else if (res.status === 400) {
      if (res.data?.applies_to_x) {
        toast.error('You should select items for Customer buys', {
          theme: 'colored'
        });
      } else if (res.data?.applies_to_y) {
        toast.error('You should select items for Customer gets', {
          theme: 'colored'
        });
      } else {
        toast.error(res.data[Object.keys(res.data)[0]][0], {
          theme: 'colored'
        });
      }
    } else {
      toast.error('Something went wrong', { theme: 'colored' });
    }
  };

  const clearField = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (value === '0') {
      setValue(name, '');
    }
  };

  const handleRouteList = () => {
    navigate('/order/campaigns');
  };

  useEffect(() => {
    if (id) {
      setValue('updated_by', user.id);
    } else {
      setValue('created_by', user.id);
    }
  }, [id]);

  useEffect(() => {
    setValue(
      'discount_type',
      discountTypes.filter(e => e.value === searchParams.get('type'))[0]?.id
    );
  }, []);

  const handleDeleteItem = () => {
    setConfirmText({
      title: 'Delete Campaign',
      description: 'Are you sure? Selected campaign will be deleted!',
      type: 'delete'
    });
    setShowConfirm(true);
  };
  useEffect(() => {
    // delete sonrası çalışması beklenen useEffect
    if (!deleteResponse) return;
    if (deleteResponse.data === '') {
      try {
        setShowConfirm(false);
        handleRouteList();
        toast.success(`Campaign deleted`, {
          theme: 'colored'
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [deleteResponse]);

  const formfields = [
    { name: 'title' },
    { name: 'discount_text' },
    { name: 'discount_type' },
    { name: 'method', str: 1 },
    { name: 'discount_code' },
    { name: 'value_type', str: 1 },
    { name: 'value' },
    { name: 'applies_type_x', str: 1 },
    { name: 'applies_to_x' },
    { name: 'applies_x_quantity' },
    { name: 'applies_x_quantity_type' },
    { name: 'applies_type_y', str: 1 },
    { name: 'applies_to_y' },
    { name: 'applies_y_quantity' },
    { name: 'is_apply_second' },
    { name: 'requirement_type', str: 1 },
    { name: 'requirement_value' },
    { name: 'customers' },
    { name: 'customer_type', str: 1 },
    { name: 'isLimitless' },
    { name: 'usage_limit' },
    { name: 'usage_count' },
    { name: 'is_automatic' },
    { name: 'is_one_usage' },
    { name: 'is_combined' },
    { name: 'start_date' },
    { name: 'end_date' },
    { name: 'wcombined', array: 1 },
    { name: 'combinedDiscounts', array: 1 }
  ];

  useEffect(() => {
    if (!data) return;

    formfields.map(field => {
      if (field.str) {
        setValue(field.name, data.data[field.name]?.toString());
      } else {
        setValue(field.name, data.data[field.name]);
      }
    });
  }, [data]);

  const formProps = {
    register,
    onSubmit,
    setValue,
    getValues,
    errors,
    control,
    discountTypesWatch,
    clearField,
    id
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardMethod {...formProps} />
        {(discountTypesWatch === 1 || discountTypesWatch === 2) && (
          <CardValue
            {...formProps}
            defaultType={data?.data.value_type}
            defaultAppliesTo={data?.data.applies_type_x}
          />
        )}
        {discountTypesWatch === 3 && <CardXY {...formProps} />}
        {discountTypesWatch !== 3 && <CardRequirements {...formProps} />}
        <CardCustomerEligibility {...formProps} />
        <CardMaxUse {...formProps} />
        <CardCombination {...formProps} />
        <CardActiveDates {...formProps} />

        <Card className="mb-3">
          <Card.Header></Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteItem}
                variant="danger"
                disabled={!id ? true : false}
                className="mx-2"
              >
                Delete
              </Button>
              <Button
                onClick={handleRouteList}
                variant="secondary"
                className="mx-2"
                style={{ width: 150 }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                {id ? 'Update' : 'Save'}
              </Button>
            </div>
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      </Form>
      <ConfirmationModal
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        title={confirmText.title}
        description={confirmText.description}
        type={confirmText.type}
        request={deleteRequest}
      />
    </>
  );
};

export default AddDiscount;
