import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { protectedApi } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';
import DraggableList from './DraggableList';
import usePost from 'hooks/usePost';
import { toast } from 'react-toastify';

const ProductAttributes = props => {
  const { formValues, setFormValues } = props;
  const [distinctValues, setDistinctValues] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [attribute, setAttribute] = useState({});
  const [selectedItem, setSelectedItem] = useState({});

  const [postResponse, postRequest] = usePost({
    url: serviceUrl.product.attribute.list,
    payload: {
      type: attribute.type?.value,
      content: attribute.content?.value
    }
  });

  const handleSelectedItem = item => {
    setSelectedItem(item);
  };

  useEffect(() => {
    if (selectedItem.id) {
      // Delete item from list
      const newAttributes = [...attributes].filter(
        dt => dt.id !== selectedItem.id
      );
      setAttributes(newAttributes);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      !formValues.attributes ||
      formValues.attributes.length === 0 ||
      formValues.attributes.filter(dt => !dt).length > 0
    )
      return;
    setAttributes(formValues.attributes);
  }, [formValues.is_update]);
  useEffect(() => {
    // Attribute listesi değiştiğinde form state'imi güncelle !
    if (attributes.length === 0 || attributes[0] === undefined) return;
    const attr_ids = attributes.map(dt => ({ id: dt.id }));
    setFormValues({
      ...formValues,
      attributes: attr_ids,
      attributes_order: attr_ids
    });
  }, [attributes]);

  useEffect(() => {
    // Submit sonrası çalışması beklenen useEffect
    if (!postResponse.data && !postResponse.error) return;
    if (postResponse.error === null) {
      toast.success(`Attribute created`, {
        theme: 'colored'
      });
      let old_attributes = [...attributes];
      const attr = {
        id: postResponse.data.id,
        type: postResponse.data.type,
        content: postResponse.data.content
      };
      old_attributes.push(attr);
      setAttributes(old_attributes);
      getDistinct();
    } else {
      if (postResponse.error.data.non_field_errors) {
        toast.error(`Already Exist!`, {
          theme: 'colored'
        });
      }
    }
  }, [postResponse]);

  const handleSave = () => {
    if (attribute.content.__isNew__ || attribute.type.__isNew__) {
      // Create attribute first
      postRequest();
    } else {
      const isAlreadyHas = attributes.some(
        e => e.type === attribute.type.value
      );
      if (isAlreadyHas) {
        toast.error(`Already Exist!`, {
          theme: 'colored'
        });
        return;
      }
      let old_attributes = [...attributes];
      const attr = {
        id: attribute.content.value,
        type: attribute.type.value,
        content: attribute.content.label
      };
      old_attributes.push(attr);
      setAttributes(old_attributes);
      setAttribute({ content: '', type: '' });
      getDistinct();
    }
  };

  const handleSelectChange = async (info, target) => {
    if (info === null) {
      setDistinctValues({});
      return;
    }
    const name = target.name;
    if (name === 'type') {
      const response = await getAttributesDistinct({
        attribute_type: info.value
      });
      const content = response.data.result.map(dt => ({
        value: dt.id,
        label: dt.content
      }));
      setDistinctValues(prevState => ({
        ...prevState,
        content: content
      }));
      setAttribute({ ...attribute, [name]: info, content: '' });
      return;
    }
    setAttribute({ ...attribute, [name]: info });
  };

  const getDistinct = async () => {
    const response = await getAttributesDistinct();
    const type = response.data.result.map(dt => ({
      value: dt.type,
      label: dt.type
    }));
    setDistinctValues({ type: type });
  };

  useEffect(() => {
    getDistinct();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Product Attributes
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label className="fs-0">Type</Form.Label>
              <CreatableSelect
                classNamePrefix="react-select"
                name="type"
                placeholder="Attribute Type"
                value={attribute?.type}
                onChange={handleSelectChange}
                options={distinctValues.type}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label className="fs-0">Content</Form.Label>
              <CreatableSelect
                classNamePrefix="react-select"
                name="content"
                placeholder="Attribute Content"
                value={attribute?.content}
                onChange={handleSelectChange}
                options={distinctValues?.content}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={2} className="position-relative">
            <Button
              variant="success"
              onClick={handleSave}
              className="mb-3"
              style={{ marginTop: 33 }}
            >
              Add
            </Button>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <DraggableList
              data={attributes}
              setState={setAttributes}
              setSelectedItem={handleSelectedItem}
              isEditable={false}
              isDeletable={true}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
};

ProductAttributes.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func
};

export default ProductAttributes;

export const getAttributesDistinct = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.attribute.distinct,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
